<template>
	<div>
		<search-list-table ref="searchListTable" :columns="columns" :formSearchList="formSearchList" :initDataSource="initDataSource">
			<template slot="btn">
				<a-button class="button" type="primary" @click="showRoleModal('add')" v-t.preserve="'page.addRole'"></a-button>
			</template>
			<template slot="scopedSlots" slot-scope="{ columnData }">
				<div class="menuTree">
					<a-tree :treeData="createTreeData(columnData.permission)" :defaultExpandAll="true" :showLine="true"> </a-tree>
				</div>
			</template>
			<template slot="action" slot-scope="{ columnData }">
				<a @click="handleEdit(columnData)">{{ $t('page.edit') }}</a>
				<a-divider type="vertical" />
				<a @click="handleDelete(columnData)">{{ $t('page.delete') }}</a>
			</template>
		</search-list-table>
		<a-modal
			v-drag-modal
			v-model="roleModal.show"
			:title="$t(roleModal.type === 'add' ? 'page.addRole' : 'page.editRole')"
			:destroyOnClose="true"
			:confirm-loading="roleModal.loading"
			width="1000px"
			@ok="handleRoleModalOk"
		>
			<div class="roleModal">
				<a-form-model
					:ref="roleModal.form"
					:rules="roleModal.rules"
					:model="roleModal.data"
					:labelCol="roleModal.labelCol"
					:wrapperCol="roleModal.wrapperCol"
				>
					<a-form-model-item :label="$t('table.Permission.Role.role_id')" v-if="roleModal.data.role_id">
						<a-input v-model="roleModal.data.role_id" disabled></a-input>
					</a-form-model-item>

					<a-form-model-item :label="$t('table.Permission.Role.roleName')" prop="role_name">
						<a-input v-model="roleModal.data.role_name"></a-input>
					</a-form-model-item>

					<a-form-model-item :label="$t('table.Permission.Role.text')">
						<a-input v-model="roleModal.data.role_remark"></a-input>
					</a-form-model-item>
					<a-form-model-item :label="$t('table.Permission.Role.webMenu')" :wrapperCol="{ span: 20 }">
						<div>
							<a-tabs :default-active-key="currentKey" @change="changeCurrentKey">
								<a-tab-pane key="web" tab="BC WEB"> </a-tab-pane>
								<a-tab-pane key="app" tab="BC APP" force-render> </a-tab-pane>
							</a-tabs>
							<a-table
								style="font-size: 12px"
								:columns="permissionTable.columns"
								size="small"
								:rowKey="(record, index) => index"
								:dataSource="tableDataSource[currentKey]"
								bordered
								:scroll="{}"
								:pagination="false"
							>
								<span slot="first" slot-scope="obj">
									<a-checkbox-group v-model="permissionTable.tempPermission[obj.indexVal].first">
										<a-row v-for="option in obj.first" :key="option.value">
											<a-checkbox :value="option.value">
												{{ $t(`enumerate.permission.${option.label}`) }}
											</a-checkbox>
										</a-row>
									</a-checkbox-group>
								</span>
								<span slot="second" slot-scope="obj">
									<a-checkbox-group v-model="permissionTable.tempPermission[obj.indexVal].second">
										<a-row v-for="option in obj.second" :key="option.value">
											<a-checkbox :value="option.value">
												{{ $t(`enumerate.permission.${option.label}`) }}
											</a-checkbox>
										</a-row>
									</a-checkbox-group>
								</span>
								<span slot="third" slot-scope="obj">
									<a-checkbox-group v-model="permissionTable.tempPermission[obj.indexVal].third">
										<a-row v-for="option in obj.third" :key="option.value">
											<a-checkbox :value="option.value" :disabled="isMutex(option, permissionTable.tempPermission[obj.indexVal].third)">
												{{ $t(`enumerate.permission.${option.label}`) }}
											</a-checkbox>
										</a-row>
									</a-checkbox-group>
								</span>
							</a-table>
						</div>
					</a-form-model-item>
				</a-form-model>
			</div>
		</a-modal>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { apiCreateRole, apiDeleteRole, apiQueryRoleList, apiUpdateRole } from '@/api/role'
import { apiQueryAllPermissionTree } from '@/api/user'

export default {
	data() {
		return {
			rangeTargetKeys: [],
			rangeSelectedKeys: [],
			teamTargetKeys: [],
			teamSelectedKeys: [],
			roleModal: {
				form: 'roleModalForm',
				labelCol: { span: 4 },
				wrapperCol: { span: 12 },
				loading: false,
				show: false,
				type: 'add',
				data: {
					role_id: '',
					role_name: '',
					role_remark: '',
					ActionList: []
				},
				rules: {
					role_name: [
						{
							required: true,
							whitespace: true
						}
					]
				}
			},
			permissionTable: {
				columns: [
					{
						// 模块
						title: this.$t('page.module'),
						scopedSlots: { customRender: 'first' }
					},
					{
						// 菜单
						title: this.$t('page.menu'),
						scopedSlots: { customRender: 'second' }
					},
					{
						// 操作
						title: this.$t('operate.operate'),
						scopedSlots: { customRender: 'third' }
					}
				],
				dataSource: [
					{
						first: ['a', 'b', 'c']
					}
				],
				tempPermission: []
			},
			permissionValue: 'menus.permissionMenu.role',
			currentKey: 'web',
			tableDataSource: {
				web: [],
				app: []
			}
		}
	},
	computed: {
		...mapGetters(['enumerate', 'permission']),
		formSearchList() {
			return [
				{
					label: 'table.Permission.Role.roleName',
					type: 'input',
					validateField: ['roleName']
				}
			]
		},
		columns() {
			return [
				{
					// 角色名称
					title: this.$t('table.Permission.Role.roleName'),
					dataIndex: 'roleName',
					align: 'center'
				},
				{
					// 角色描述
					title: this.$t('table.Permission.Role.text'),
					dataIndex: 'text',
					align: 'center'
				},
				{
					// Web菜单
					title: this.$t('table.Permission.Role.webMenu'),
					scopedSlots: { customRender: 'scopedSlots' },
					align: 'center'
				},
				{
					// 操作
					title: this.$t('operate.operate'),
					scopedSlots: { customRender: 'action' },
					align: 'center'
				}
			]
		}
	},
	async mounted() {
		let allPermission = await apiQueryAllPermissionTree()
		this.$store.commit('permission/setAllPermission', allPermission)
		this.permission.allPermission.dataSource.map((item) => {
			if (item.type === 'web') {
				this.tableDataSource.web.push(item)
			} else {
				this.tableDataSource.app.push(item)
			}
		})
		this.initTempPermission()
	},
	methods: {
		initDataSource(initParams) {
			return apiQueryRoleList(initParams)
		},
		changeCurrentKey(key) {
			this.currentKey = key
		},
		resetRoleModal() {
			this.roleModal.data = {
				role_id: '',
				role_name: '',
				role_remark: '',
				business_auth: '',
				ActionList: []
			}
			this.teamTargetKeys = []
			this.rangeTargetKeys = []
		},
		showRoleModal(type, data) {
			this.resetTempPermission()
			this.roleModal.type = type
			this.resetRoleModal()

			if (type === 'edit') {
				// for (let permissionId of data.permission) {
				// 	for (let [i, line] of Object.entries(this.permission.allPermission.dataSource)) {

				// 		if (permissionId === (line.first[0] && line.first[0].value)) {
				// 			this.permissionTable.tempPermission[i].first.push(permissionId)
				// 		}
				// 		if (line.second.length > 0 && permissionId === line.second[0].value) {
				// 			this.permissionTable.tempPermission[i].second.push(line.second[0].value)
				// 		}
				// 	}
				// }
				Object.values(this.permission.allPermission.dataSource).forEach((oneLine) => {
					const { first, second, third, indexVal } = oneLine
					if (first.length > 0 && data.permission.includes(first[0].value)) {
						this.permissionTable.tempPermission[indexVal].first.push(first[0].value)
					}
					if (second.length > 0 && data.permission.includes(second[0].value)) {
						this.permissionTable.tempPermission[indexVal].second.push(second[0].value)
					}
					if (third.length > 0) {
						third.forEach((thirdItem) => {
							if (data.permission.includes(thirdItem.value)) {
								this.permissionTable.tempPermission[indexVal].third.push(thirdItem.value)
							}
						})
					}
				})
				// 角色部分
				this.roleModal.data.role_id = data.id
				this.roleModal.data.role_name = data.roleName
				this.roleModal.data.role_remark = data.text
				this.roleModal.data.business_auth = data.business_auth_permission
			}
			this.roleModal.show = true
		},
		createTreeData(source, callback) {
			if (!source) {
				return []
			}
			let data = []
			for (let rootMenu of this.permission.tree) {
				if (source.includes(rootMenu.id)) {
					let menu = JSON.parse(JSON.stringify(rootMenu))
					menu.title = this.$t(`enumerate.permission.${menu.permissionName}`)
					menu.children = []
					data.push(menu)
				}
			}
			for (let rootMenu of data) {
				for (let child of rootMenu.sub) {
					if (source.includes(child.id)) {
						child.title = this.$t(`enumerate.permission.${child.permissionName}`)
						rootMenu.children.push(child)
					}
				}
			}
			// callback(data)
			return data
		},
		handleTransferChange(nextTargetKeys, direction, moveKeys) {
			this.targetKeys = nextTargetKeys
		},
		handleTransferSelectChange(sourceSelectedKeys, targetSelectedKeys) {
			this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys]
		},
		async handleDelete(obj) {
			this.$confirm({
				content: this.$t('page.confirm'),
				onOk: async () => {
					let params = {
						id: obj.id
					}
					try {
						let res = await apiDeleteRole(params)
						let roleIdList = (await apiQueryRoleList({ pageNum: 0, pageSize: 999999 })).pageItems
						this.$store.commit('permission/setRoleId', roleIdList)
						this.$refs.searchListTable.initTable(1)
					} catch (e) {
						this.$error({
							content: e.message
						})
					}
				}
			})
		},
		async handleEdit(obj) {
			this.showRoleModal('edit', obj)
		},
		async handleRoleModalOk() {
			await this.$refs.roleModalForm.validate()
			let params = {
				roleName: this.roleModal.data.role_name,
				text: this.roleModal.data.role_remark,
				id: this.roleModal.data.role_id
			}
			params.permission = []
			for (let line of this.permissionTable.tempPermission) {
				for (let i of line.first) {
					params.permission.push(i)
				}
				for (let i of line.second) {
					params.permission.push(i)
				}
				for (let i of line.third) {
					params.permission.push(i)
				}
			}

			params = this.removeUnuseParam(params)
			this.roleModal.loading = true
			try {
				if (this.roleModal.type === 'add') {
					let res = await apiCreateRole(params)
				} else if (this.roleModal.type === 'edit') {
					let res = await apiUpdateRole(params)
				}
				this.roleModal.loading = false
				this.roleModal.show = false

				let roleIdList = (await apiQueryRoleList({ pageNum: 0, pageSize: 999999 })).pageItems
				this.$store.commit('permission/setRoleId', roleIdList)

				this.$refs.searchListTable.initTable(1)
			} finally {
				this.roleModal.loading = false
			}
		},
		initTempPermission() {
			this.permissionTable.tempPermission = []
		},
		resetTempPermission() {
			this.permissionTable.tempPermission = []
			for (let i of this.permission.allPermission.dataSource) {
				this.permissionTable.tempPermission.push({
					first: [],
					second: [],
					third: []
				})
			}
		},
		removeUnuseParam(params) {
			for (let key in params) {
				// 移除未定义参数
				if (params[key] === undefined || params[key] === '') {
					delete params[key]
				}
			}
			return params
		},
		// 是否互斥，Maker和Checker互斥，只能二选一
		isMutex(current, selectedValues) {
			if (current.label.includes('MAKER') || current.label.includes('CHECKER')) {
				const selectedOpts = this.permission.allPermission.tree.filter((i) => selectedValues.includes(i.id))
				const permissionNameStr = `${current.label}-${selectedOpts.map((i) => i.permissionName).join('-')}`
				return permissionNameStr.includes('MAKER') && permissionNameStr.includes('CHECKER')
			} else {
				return false
			}
		}
	}
}
</script>

<style scoped lang="less">
.title button {
	float: right;
	margin-right: 10px;
}

.permissionSetting {
	border: 1px solid #e8e8e8;
	border-radius: 2px;
	padding-left: 10px;
	padding-right: 10px;
}

.menuTree {
	text-align: left;
}
</style>
